import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from 'src/state/AppState'
import styled from '@emotion/styled'
import { mq } from 'src/styles'
import { slowSpeed, bezier } from 'src/styles/animations'
import { Link } from 'gatsby'
import './header.css'

const Wrapper = styled.div`
	mix-blend-mode: difference;
	color: #fff;
	svg {
		* {
			fill: currentcolor;
		}
	}
`

const PageTitle = styled.h1`
	order: 1;
	// flex-grow: 1;
	transition: transform ${ slowSpeed }ms ${ bezier };
	${ ({ modalOpen }) => modalOpen ? `
		${ mq.largeAndUp } {
			transform: translateX(calc(-50% - 20vw - var(--site-margins)));
		}
		${ mq.extraLargeAndUp } {
			transform: translateX(calc(-50% - var(--site-margins)));
		}
	` : `` }
`

const Toggle = styled.input`
	appearance: none;
	width: 32px;
	height: 16px;
	background: #fff;
	border-radius: 20px;
	cursor: pointer;
	&:checked {
		&:after {
			transform: none;
		}
	}
	&:after {
		transform: translate3d(100%, 0, 0);
		transition: transform var(--md-speed) ease-in-out;
		content: '';
		display: block;
		border-radius: 50%;
		width: 16px;
		height: 16px;
		border: 2px solid #fff;
		background: #000;
	}
`

const HeaderLinkWrapper = styled.div`
	flex-grow: 1;
	a, button {
		appearance: none;
		padding: 0;
		border: none;
		outline: none;
		color: currentcolor;
		box-shadow: none;
	}
`

const HeaderNav = styled.nav`
	ul li:not(:last-of-type) {
		display: flex;
		&:after {
			content: ',';
			margin-right: .2em;
		}
	}
`

const Header = ({ children }) => {
	const { setDarkMode, darkMode } = useContext(AppContext)

  return (
		<>
			<a href="mailto:info@hyperlinknyc.com" style={{ color: '#000' }} className="h-[24px] body-small bg-main overflow-hidden flex flex-nowrap items-center justify-center">
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
				<div className='ticker shrink-0 font-semibold pl-[8em]'>Now accepting projects</div>
			</a>
			<Wrapper className='sticky top-0 left-0 right-0 z-1 p-margin -mb-header flex justify-between z-10'>
				<Link to='/' className='inline-block vertical-align-top'>
					<h1 className='grow-0 w-[60px]'>
						<svg title='Studio Hyperlink' width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M18.5051 22L28.4242 0H26.0124L22.5438 7.6912H20.1276L23.5962 0H21.18L17.7158 7.6912H13.699L17.1676 0H14.7515L10.283 9.8956H21.5527L16.0889 22H18.5051Z" fill="white"/>
							<path d="M18.1411 12.1044L13.6771 22H11.2609L14.7295 14.3088H10.7128L7.24418 22H4.83238L8.30098 14.3088H5.8848L2.41619 22H0L9.91909 0H12.3353L6.87144 12.1044H18.1411Z" fill="white"/>
						</svg>
					</h1>
				</Link>
 				
				{/*
				<div><HeaderLinkWrapper className='hover-underline'><Link href="/">Projects</Link></HeaderLinkWrapper></div>
				<div><HeaderLinkWrapper className='hover-underline'><Link to="/information">Information</Link></HeaderLinkWrapper></div>
				<div><HeaderLinkWrapper className='hover-underline'><a href="mailto:hi@hyperlinknyc.com">Contact</a></HeaderLinkWrapper></div>
				*/}
				
				<div className='grow-0 w-[60px] flex items-center justify-end'>
					<Toggle title='Dark Mode' onChange={() => setDarkMode(!darkMode)} type='checkbox' />
				</div>
			</Wrapper>
		</>
  )
}

export default Header
