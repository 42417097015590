import React, { useEffect, useState } from 'react'
import AppProvider from 'src/state/AppState'
import Header from 'src/components/Header'

import { isBrowser, isMobileSafari, useWindowSize } from 'src/util/helpers'

import 'src/styles/globalStyles.css'
import 'src/styles/typography.css'
import 'src/styles/fonts.css'

const Layout = ({ children, location }) => {
	// set window height var (w/ safari/iOS hack)
  const { height: windowHeight } = useWindowSize()
  const [lockHeight, setLockHeight] = useState(false)
	const [vw, setVw] = useState(false)
  const hasChin = isMobileSafari()

	useEffect(() => {
    if ((isBrowser && !lockHeight) || !hasChin) {
      document.body.style.setProperty('--vh', `${windowHeight * 0.01}px`)
      setLockHeight(hasChin)
			setVw(windowHeight)
    }
  }, [hasChin])

	return (
		<>
			<AppProvider>
				<>
					<Header/>
					{children}
				</>
			</AppProvider>
		</>
	)
}

export default Layout